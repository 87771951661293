import React,{Fragment} from 'react';

const Support = () => {
    return (
        <Fragment>
            <iframe title="uncap-support" className="clickup-embed clickup-dynamic-height"
                    src="https://forms.clickup.com/10582279/f/a2y87-30164/YFQBA8SF2GTOLWAHRJ" onWheel="" width="100%"
            ></iframe>
            <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
        </Fragment>
    );
};

export default Support;