import React, {useEffect, useState, useCallback, Fragment} from 'react';
import {
    Page,
    Layout,
    BlockStack,
    Text,
    Card,
    SkeletonBodyText,
    InlineStack,
    Button,
    Modal,
    TextField,
    RadioButton,
    Toast,
    EmptySearchResult,
    IndexTable,
    Box
} from "@shopify/polaris"
import {apiService, baseUrl} from "../../Utils/Constants";
import {EditIcon, DeleteIcon} from "@shopify/polaris-icons";
import {useNavigate} from "react-router-dom";

const initialState = {
    id: "",
    name: "",
    label: "",
    type :"1"
}

const FormFields = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [fieldList, setFieldList] = useState([]);
    const [field, setField] = useState(initialState);
    const [isFieldModal, setFieldModal] = useState(false);
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [active, setActive] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    let navigate = useNavigate()
    useEffect(() => {
        getFields();
    }, []);

    const getFields = async () => {
        setIsLoading(true);
        const response = await apiService.getFields();
        if (response.status === 200) {
            setFieldList(response.data);
            setIsLoading(false);
        } else {
            setIsLoading(false)
        }
    };

    const onEdit = useCallback((record) => {
        setField(record);
        setFieldModal(true)
    }, []);

    const onDeleteModal = useCallback((record, index) => {
        setField(record);
        setSelectedIndex(index);
        setDeleteModal(true)
    }, []);

    const handleChange = useCallback(() => {
        setFieldModal(!isFieldModal)
        setField(initialState);
    }, [isFieldModal]);

    const handleChangeDelete = useCallback(() => {
        setDeleteModal(!isDeleteModal)
        setField(initialState);
        setSelectedIndex(null)
    }, [isDeleteModal]);

    const onChange = (name, value) => {
        setField({...field, [name]: value})
    };

    const onCreateField = async () => {
        setIsSave(true);
        const payload = {
            ...field
        };
        const response = await apiService.createField(payload);
        if (response.status === 200) {
            setIsSave(false);
            setField(initialState);
            setFieldModal(false);
            setActive(true);
            setMessage(response.message);
            getFields()
        } else {
            setIsSave(false);
            setActive(true);
            setMessage(response.message);
            setError(true)
        }
    };

    const toggleActive = () => {
        setActive(false);
        setError(false);
        setMessage("");
    };

    const onDeleteRecord = async () => {
        setIsSave(true);
        const response = await apiService.deleteField(field.id)
        if (response.status === 200) {
            const clone = [...fieldList];
            clone.splice(selectedIndex, 1);
            setFieldList(clone);
            setIsSave(false);
            setField(initialState);
            setDeleteModal(false);
            setActive(true);
            setMessage(response.message);
            setSelectedIndex(null)

        } else {
            setIsSave(false);
            setActive(true);
            setMessage(response.message);
            setError(true)
        }
    }

    const toastMarkup = active ? (
        <Toast
            content={message}
            error={error}
            onDismiss={toggleActive}
            duration={2000}
        />
    ) : null;

    const resourceName = {
        singular: 'customer',
        plural: 'customers',
    };

    const rowMarkup = (fieldList || []).map((x, index,) => (
            <IndexTable.Row
                id={x.id}
                key={x.id}
                position={index}
            >
                <IndexTable.Cell>
                    <Text>
                        {x.label}
                    </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>
                        <InlineStack gap={"200"} align={"end    "}>
                            <Button icon={EditIcon} onClick={() => onEdit(x)}/>
                            <Button icon={DeleteIcon} onClick={() => onDeleteModal(x, index)}/>
                        </InlineStack>
                </IndexTable.Cell>

            </IndexTable.Row>
        ),
    );
    return (
        <Fragment>
            {
                toastMarkup
            }
            <Fragment>
                {
                    isFieldModal && <Modal
                        open={isFieldModal}
                        onClose={handleChange}
                        title="Request FormFields Field"
                        primaryAction={{
                            content: 'Save',
                            onAction: onCreateField,
                            loading: isSave
                        }}
                        secondaryActions={[
                            {
                                content: 'Close',
                                onAction: handleChange,
                            },
                        ]}
                    >
                        <Modal.Section>
                            <BlockStack gap={"300"}>
                                <TextField label="Label" value={field.label} onChange={(value) => onChange("label", value)}/>

                                <BlockStack gap={"0"}>
                                    <RadioButton label="Single line text" checked={field.type == "1"} onChange={() => onChange("type", "1")}/>
                                    <RadioButton label="Multi-line text" checked={field.type == "2"} onChange={() => onChange("type", "2")}/>
                            </BlockStack>
                            </BlockStack>
                        </Modal.Section>
                    </Modal>
                }
            </Fragment>
            <Fragment>
                {
                    isDeleteModal && <Modal
                        open={isDeleteModal}
                        onClose={handleChangeDelete}
                        title="Delete Request FormFields Field"
                        primaryAction={{
                            content: 'Delete',
                            onAction: onDeleteRecord,
                            loading: isSave
                        }}
                        secondaryActions={[
                            {
                                content: 'Close',
                                onAction: handleChangeDelete,
                            },
                        ]}
                    >
                        <Modal.Section>
                            <Text>Are you sure you want to Delete? This action cannot be reversed.</Text>
                        </Modal.Section>
                    </Modal>
                }
            </Fragment>
            <Page title="Form Fields"  backAction={{content: 'Setting', onAction: () => navigate(`${baseUrl}/setting`)}} primaryAction={{
                content: 'Create Field',
                onAction: () => setFieldModal(true)
            }}>
                <Layout>
                    <Layout.Section variant="oneThird">
                        <Card>
                            <BlockStack gap={"400"}>
                                <Text id="storeDetails" variant="headingMd" as="h2">
                                    Quickly create form fields for the cart page
                                </Text>
                                <BlockStack align={"end"}>
                                    <Text variant="bodyMd" color="subdued" as="p">
                                        To create form fields click on "Create Field" give the "Label" name, select the form field type single-line text or multi-line text and click on "Save". Easily creates fields such as Shipping Address, Contact Number, Additional Notes, etc.
                                    </Text>
                                </BlockStack>
                            </BlockStack>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card padding={"0"}>
                            <IndexTable
                                emptyState={<EmptySearchResult title={'No Quotes Order(s) found'} withIllustration={!isLoading}/>}
                                resourceName={resourceName}
                                itemCount={isLoading ? 1 : fieldList.length}
                                headings={[
                                    {title:"Fields Name"},
                                    {title:"Actions",alignment:"end"},
                                ]}
                                selectable={false}
                            >
                                {
                                    isLoading ?
                                        Array.from(Array(5)).map((_, i) => (
                                            <IndexTable.Row key={i}>
                                                <IndexTable.Cell><Box padding={"200"}><SkeletonBodyText lines={1}/></Box></IndexTable.Cell>
                                                <IndexTable.Cell><Box padding={"200"}><SkeletonBodyText lines={1}/></Box></IndexTable.Cell>
                                            </IndexTable.Row>
                                        )) :
                                        rowMarkup}
                            </IndexTable>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </Fragment>
    );
};

export default FormFields;