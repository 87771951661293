import {lazy} from "react";
import {baseUrl} from "./Constants";
import QuoteOrderDetails from "../Component/QuoteOrder/QuoteOrderDetails";
import QuoteOrder from "../Component/QuoteOrder/QuoteOrder";
import Setting from "../Component/Setting/Setting";
import FormFields from "../Component/FormField/FormFields";
import ButtonCondition from "../Component/ButtonCondition/ButtonCondition";
import ButtonDesign from "../Component/ButtonDesign/ButtonDesign";
import PlanNew from "../Component/Plan/PlanNew";
import Installation from "../Component/Installation/Installation";
import Support from "../Component/Support/Support";
const Dashboard = lazy(() => import(  "../Component/Dashboard/Dashboard"));



export const routes = [
    {path: `${baseUrl}/dashboard`, component: <Dashboard/>},
    {path: `${baseUrl}/quote-order/:id`, component: <QuoteOrderDetails/>},
    {path: `${baseUrl}/quote-order`, component: <QuoteOrder/>},
    {path: `${baseUrl}/setting`, component: <Setting/>},
    {path: `${baseUrl}/setting/form-field`, component: <FormFields/>},
    {path: `${baseUrl}/setting/button-condition`, component: <ButtonCondition/>},
    {path: `${baseUrl}/setting/button-design`, component: <ButtonDesign/>},
    {path: `${baseUrl}/pricing-plan`, component: <PlanNew/>},
    {path: `${baseUrl}/installation`, component: <Installation/>},
    {path: `${baseUrl}/support`, component: <Support/>},
    {path: `${baseUrl}/`, component: <Dashboard/>},
]