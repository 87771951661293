import React, {Fragment, useState, useEffect} from 'react';
import {
    Button,
    Card,
    Checkbox,
    Layout,
    Page,
    Select,
    Text,
    TextField,
    Thumbnail,
    Toast,
    Tooltip,
    BlockStack,
    Grid,
    ResourceItem,
    ResourceList,
    InlineStack,
    Tag,
    Listbox,
    Combobox,
    AutoSelection,
} from "@shopify/polaris";
import {DeleteIcon, ImageIcon} from "@shopify/polaris-icons";
import {ResourcePicker} from "@shopify/app-bridge-react";
import {apiService, baseUrl, currencySymbol} from "../../Utils/Constants";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const initialState = [
    {
        type: "1",
        products: [],
        collections: [],
        customer_tags: [],
        product_tags: [],
        total_condition: "1",
        max: "",
        min: ''
    }
]

const ButtonCondition = () => {
    const [quoteCondition, setQuoteCondition] = useState(initialState);
    const [isProduct, setIsProduct] = useState(false);
    const [isCollection, setIsCollection] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [active, setActive] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [draftOrderTags, setDraftOrderTags] = useState([]);
    const [isAddToCartQuotes, setIsAddToCartQuotes] = useState(0);
    const [ids, setIds] = useState("");
    const [selectedIndex,setSelectedIndex]=useState(null)
    const [value,setValue]=useState("")
    const [draftOrderValue,setDraftOrderValue]=useState("");
    let navigate = useNavigate()
    const shopDetails = useSelector((state) => state.shopDetails);

    useEffect(() => {
        const getQuoteRule = async () => {
            const response = await apiService.getQuoteRule()
            if (response.status === 200) {
                let clone = []
                setIds(response.data.id)
                setDraftOrderTags(response.data.draft_order_tags)
                Object.keys(response.data).map((x) => {
                    let obj = {}
                    if (x === "is_all_product" && response.data[x] === 1) {
                        obj = {
                            ...obj,
                            type: "1",
                            products: [],
                            collections: [],
                            customer_tags: [],
                            product_tags: [],
                            total_condition: "",
                            max: "",
                            min: '',
                            id: response.data.id,
                        }
                        clone.push(obj)
                    } else if (x === "products" && response.data[x] && response.data[x].length) {
                        let product_ids = [];
                        response.data[x].map((p) => {
                            let id = {...p, id: `gid://shopify/Product/${p.shopify_product_id}`}
                            product_ids.push(id)
                        })
                        obj = {
                            ...obj,
                            type: "2",
                            products: product_ids,
                            collections: [],
                            product_tags: [],
                            total_condition: "",
                            max: "",
                            min: '',
                            id: response.data.id
                        }
                        clone.push(obj)
                    } else if (x === "collections" && response.data[x] && response.data[x].length) {
                        let collection_ids = [];
                        response.data[x].map((x) => {
                            let obj = {...x, id: `gid://shopify/Collection/${x.shopify_collection_id}`}
                            collection_ids.push(obj)
                        })
                        obj = {
                            ...obj,
                            type: "3",
                            products: [],
                            collections: collection_ids,
                            product_tags: [],
                            total_condition: "",
                            max: "",
                            min: '',
                        }
                        clone.push(obj)
                    } else if (x === "total_condition" && response.data[x]) {
                        obj = {
                            ...obj,
                            type: "4",
                            products: [],
                            collections: [],
                            product_tags: [],
                            total_condition: response.data[x],
                            max: "",
                            min: ''
                        }
                        clone.push(obj)
                    } else if (x === "customer_tags" && response.data[x] && response.data[x].length) {
                        obj = {
                            ...obj,
                            type: "5",
                            products: [],
                            collections: [],
                            customer_tags: response.data[x],
                            total_condition: "",
                            max: "",
                            min: ''
                        }
                        clone.push(obj)
                    } else if (x === "product_tags" && response.data[x] && response.data[x].length) {
                        obj = {
                            ...obj,
                            type: "6",
                            products: [],
                            collections: [],
                            customer_tags: [],
                            product_tags: response.data[x],
                            total_condition: "",
                            max: "",
                            min: ''
                        }
                        clone.push(obj)
                    }
                })
                setQuoteCondition(clone)
                setIsAddToCartQuotes(response.data.is_add_to_cart_quotes)
            } else {

            }
        };
        getQuoteRule()
    }, [])
    const onSelectProduct = (record) => {
        let clone = [...quoteCondition];
        const index = clone.findIndex((x) => x.type === "2")
        if (index !== -1) {
            let product = [];
            record.selection.map((x) => {
                let obj = {
                    id: x.id,
                    title: x.title,
                    handle: x.handle,
                    image: x?.images[0]?.originalSrc || ""
                }
                product.push(obj)
            })
            clone[index].products = product;
            setQuoteCondition(clone)
            setIsProduct(false)
        }
    };
    const onSelectCollection = (record) => {
        let clone = [...quoteCondition];
        const index = clone.findIndex((x) => x.type === "3")
        if (index !== -1) {
            let collections = [];
            record.selection.map((x) => {
                let obj = {
                    handle: x.handle,
                    image: x?.image?.originalSrc || "",
                    id: x.id,
                    title: x.title,
                }
                collections.push(obj)
            })
            clone[index].collections = collections;
            setQuoteCondition(clone)
            setIsCollection(false)
        }
    };
    const onCloseProduct = () => {
        setIsProduct(false)
    };
    const onOpenProduct = () => {
        setIsProduct(true)
    };
    const onCloseCollection = () => {
        setIsCollection(false)
    };
    const onOpenCollection = () => {
        setIsCollection(true)
    };
    const onDeleteProduct = (id) => {
        let clone = [...quoteCondition];
        const index = clone.findIndex((x) => x.type === "2")
        const indexProduct = clone[index].products.findIndex((x) => x.id === id);
        if (indexProduct !== -1) {
            clone[index].products.splice(indexProduct, 1);
            setQuoteCondition(clone)
        }
    };
    const onDeleteCollection = (id) => {
        let clone = [...quoteCondition];
        const index = clone.findIndex((x) => x.type === "3")
        const indexProduct = clone[index].collections.findIndex((x) => x.id === id);
        if (indexProduct !== -1) {
            clone[index].collections.splice(indexProduct, 1);
            setQuoteCondition(clone)
        }
    };
    const onChange = (name, value, index) => {
        let clone = [...quoteCondition];
        if (name === "type" && value == 1) {
            clone[index][name] = value;
            clone = clone.filter((y) => y.type !== "2")
            clone = clone.filter((y) => y.type !== "3")
            setQuoteCondition(clone)
        } else if (name === "type" && (value == 2 || value == 3)) {
            clone[index][name] = value;
            clone = clone.filter((y) => y.type !== "1")
            setQuoteCondition(clone)
        } else {
            clone[index][name] = value;
            setQuoteCondition(clone)
        }
    }
    const onAddNewRule = () => {
        let clone = [...quoteCondition];
        if (clone && clone.length) {
            const max = Math.max.apply(null, clone.map(item => Number(item.type)));
            let obj = {
                type: max === 1 ? "4" : `${max + 1}`,
                products: [],
                collections: [],
                customer_tags: [],
                product_tags: [],
                total_condition: "1",
                is_all_product: 0,
                max: "",
                min: ''
            }
            clone.push(obj);
        } else {
            let obj = {
                type: "1",
                products: [],
                collections: [],
                customer_tags: [],
                product_tags: [],
                total_condition: "1",
                is_all_product: 1,
                max: "",
                min: ''
            }
            clone.push(obj);
        }
        setQuoteCondition(clone)
    }

    const handleChangeDraftOrder = (value) => {
        let values = value.filter((v, i, a) => a.indexOf(v) === i)
        setDraftOrderTags(values)
    };

    const onDeleteRule = (index) => {
        let clone = [...quoteCondition];
        clone.splice(index, 1);
        setQuoteCondition(clone)
    }
    const toggleActive = () => {
        setActive(false);
        setError(false);
        setMessage("");
    };

    const toastMarkup = active ? (
        <Toast
            content={message}
            error={error}
            onDismiss={toggleActive}
            duration={2000}
        />
    ) : null;

    const onUpdateQuoteRule = async () => {
        setIsSave(true);
        let payload = {
            id: ids,
            is_all_product: 0,
            customer_tags: [],
            product_tags: [],
            product_ids: [],
            collection_ids: [],
            total_condition: "",
            min: "",
            max: ""
        }
        quoteCondition.map((x) => {
            if (x.type === "1") {
                payload = {...payload, is_all_product: 1,}
            } else if (x.type === "2" && payload.is_all_product !== 1) {
                let product_ids = []
                x.products.map((p) => {
                    let id = p.id.replace("gid://shopify/Product/", "")
                    product_ids.push(id)
                })
                payload = {...payload, product_ids: product_ids}
            } else if (x.type === "3" && payload.is_all_product !== 1) {
                let collection_ids = []
                x.collections.map((p) => {
                    let id = p.id.replace("gid://shopify/Collection/", "")
                    collection_ids.push(id)
                })
                payload = {...payload, collection_ids: collection_ids,}
            } else if (x.type === "4") {
                payload = {...payload, total_condition: x.total_condition, max: x.max, min: x.min}
            } else if (x.type === "5") {
                payload = {...payload, customer_tags: x.customer_tags}
            } else if (x.type === "6") {
                payload = {...payload, product_tags: x.product_tags}
            }
        })

        const response = await apiService.updateQuoteRule({
            ...payload,
            draft_order_tags: draftOrderTags,
            is_add_to_cart_quotes: isAddToCartQuotes
        })
        if (response.status === 200) {
            setIsSave(false);
            setActive(true);
            setMessage(response.message);
        } else {
            setIsSave(false);
            setActive(true);
            setMessage(response.message);
            setError(true)
        }

    }
    const updateSelection = (selected,index,name) => {
        let clone = [...quoteCondition];
        const data = [...clone[index][name]];
        if (!data.includes(selected)) {
            data.push(selected);
        }
        clone[index][name] = data;
        setQuoteCondition(clone);
        setValue("");
        setSelectedIndex(null)
    };

    const removeTag = (tag,index,name) => () => {
        let clone = [...quoteCondition];
        const data = [...clone[index][name]];
        const nextSelectedTags = new Set([...data]);
        nextSelectedTags.delete(tag);
        clone[index][name] = nextSelectedTags
        setQuoteCondition(clone);
    };

    const verticalContentMarkup = (data,index,name) => {
        return data.length > 0 ? (
            <InlineStack gap={"200"}>
                {data.map((tag) => (
                    <Tag onRemove={removeTag(tag,index,name)}>
                        {tag}
                    </Tag>
                ))}
            </InlineStack>
        ) : null;
    };

    const onChangeInput = (e,index) => {
        setValue(e);
        setSelectedIndex(index)
    };

    const onChangeInputDraftOrder = (values)=>{
        setDraftOrderValue(values)
    }
    const verticalContentMarkupDraftOrder =(data)=>{
        return data.length  > 0 ? (
            <InlineStack gap={"200"}>
                { (data && data || []).map((tag, i) => (
                    <Tag onRemove={removeTagDraftOrder(i)}>
                        {tag}
                    </Tag>
                ))}
            </InlineStack>
        ) : null;
    }

    const updateSelectionDraftOrder = (selected) => {
        let clone = [...draftOrderTags];
        if (!clone.includes(selected)) {
            clone.push(selected);
        }
        setDraftOrderTags(clone);
        setDraftOrderValue("");
    };

    const removeTagDraftOrder = (tag) => () => {
        let clone = [...draftOrderTags];
        clone.splice(tag, 1);
        setDraftOrderTags(clone);
    };

    return (
        <Fragment>
            {toastMarkup}
            <ResourcePicker
                resourceType={"Product"}
                showVariants={false}
                selectMultiple={true}
                initialSelectionIds={quoteCondition.find((x) => x.type === "2")?.products || []}
                open={isProduct}
                onSelection={(SelectPayload) => onSelectProduct(SelectPayload)}
                onCancel={onCloseProduct}
            />
            <ResourcePicker
                resourceType={"Collection"}
                selectMultiple={true}
                initialSelectionIds={quoteCondition.find((x) => x.type === "3")?.collections || []}
                open={isCollection}
                onSelection={(SelectPayload) => onSelectCollection(SelectPayload)}
                onCancel={onCloseCollection}
            />
            <Page title="Button Conditions"
                  primaryAction={{
                      content: 'Save',
                      onAction: onUpdateQuoteRule,
                      loading: isSave
                  }}
                  backAction={{content: 'Setting', onAction: () => navigate(`${baseUrl}/setting`)}}>
                <Layout>
                    <Layout.Section variant={"oneThird"}>
                        <Card>
                            <BlockStack gap={"400"}>
                                <Text id="storeDetails" variant="headingMd" as="h2">
                                    Simply set button conditions to show offers
                                </Text>
                                <Text variant="bodyMd" color="subdued" as="p">
                                    To set button conditions, give some eligibility rules & set conditions to "Show offer
                                    for" based on All Products, Specific Products, Specific Collection, Cart Value Range,
                                    Customer Tags, and Product Tags. After completing the eligibility rules click on Save.
                                </Text>
                            </BlockStack>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card>
                            <BlockStack gap={"400"}>
                                <Text variant="headingMd" as="h2">Eligibility rules</Text>
                                    {
                                        quoteCondition.map((x, i) => {
                                        return (
                                            <Fragment key={i}>
                                                <Grid>
                                                    <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: quoteCondition.length > 1 ? 11 :12, xl: quoteCondition.length > 1 ? 11 :12}}>
                                                        <Select
                                                            value={x.type}
                                                            label={"Show offer for"}
                                                            onChange={(value) => onChange("type", value, i)}
                                                            options={[
                                                                {
                                                                    label: "All products",
                                                                    value: "1",
                                                                    disabled: quoteCondition.filter((y) => y.type === "1").length > 0
                                                                },
                                                                {
                                                                    label: "Specific products",
                                                                    value: "2",
                                                                    disabled: quoteCondition.filter((y) => y.type === "2").length > 0
                                                                },
                                                                {
                                                                    label: "Specific collection",
                                                                    value: "3",
                                                                    disabled: quoteCondition.filter((y) => y.type === "3").length > 0
                                                                },
                                                                {
                                                                    label: "Cart value range",
                                                                    value: "4",
                                                                    disabled: quoteCondition.filter((y) => y.type === "4").length > 0
                                                                },
                                                                {
                                                                    label: "Customer tags",
                                                                    value: "5",
                                                                    disabled: quoteCondition.filter((y) => y.type === "5").length > 0
                                                                },
                                                                {
                                                                    label: "Product tags",
                                                                    value: "6",
                                                                    disabled: quoteCondition.filter((y) => y.type === "6").length > 0
                                                                },
                                                            ]}
                                                        />
                                                    </Grid.Cell>
                                                    <BlockStack align={"end"} inlineAlign={"end"}>
                                                        <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 1, xl: 1}}>
                                                        {
                                                            quoteCondition.length > 1 ?
                                                                    <span>
                                                                        <Button icon={DeleteIcon}
                                                                        onClick={() => onDeleteRule(i)}/>
                                                                    </span>
                                                                : ""
                                                        }
                                                    </Grid.Cell>
                                                    </BlockStack>
                                                </Grid>
                                                {
                                                    x.type === "2" && <Fragment>
                                                        <ResourceList
                                                            resourceName={{singular: 'customer', plural: 'customers'}}
                                                            items={x.products}
                                                            renderItem={(item) => {
                                                                const {id, url, name,image,title} = item;
                                                                return (
                                                                    <ResourceItem
                                                                        id={id}
                                                                        url={url}
                                                                        media={
                                                                            <Thumbnail customer size="small" name={name} source={image ? image : ImageIcon} />
                                                                        }
                                                                        accessibilityLabel={`View details for ${name}`}
                                                                        name={name}
                                                                    >
                                                                        <InlineStack align={"space-between"}>
                                                                            <span className={"resource-item-title"}>
                                                                                <Text variant="bodyMd" fontWeight="bold" as="h3">
                                                                                    {title}
                                                                                </Text>
                                                                            </span>
                                                                             <Tooltip content="Delete product"
                                                                                      dismissOnMouseOut
                                                                                      preferredPosition="above"><Button
                                                                                 icon={DeleteIcon}
                                                                                 onClick={() => onDeleteProduct(id)}/>
                                                                             </Tooltip>
                                                                        </InlineStack>
                                                                    </ResourceItem>
                                                                );
                                                            }}
                                                        />
                                                        <span>
                                                            <Button primary onClick={onOpenProduct}>Add Product</Button>
                                                        </span>
                                                    </Fragment>
                                                }
                                                {
                                                    x.type === "3" && <Fragment>
                                                        <ResourceList
                                                            resourceName={{singular: 'customer', plural: 'customers'}}
                                                            items={x.collections}
                                                            renderItem={(item) => {
                                                                const {id, url, name,image,title} = item;
                                                                return (
                                                                    <ResourceItem
                                                                        id={id}
                                                                        url={url}
                                                                        media={
                                                                            <Thumbnail customer size="small" name={name} source={image? image: ImageIcon} />
                                                                        }
                                                                        accessibilityLabel={`View details for ${name}`}
                                                                        name={name}
                                                                    >
                                                                        <InlineStack align={"space-between"}>
                                                                            <span className={"resource-item-title"}>
                                                                                <Text variant="bodyMd" fontWeight="bold" as="h3">
                                                                                    {title}
                                                                                </Text>
                                                                            </span>
                                                                            <Tooltip content="Delete product"
                                                                                     dismissOnMouseOut
                                                                                     preferredPosition="above"><Button
                                                                                icon={DeleteIcon}
                                                                                onClick={() => onDeleteCollection(id)}/>
                                                                            </Tooltip>
                                                                        </InlineStack>
                                                                    </ResourceItem>
                                                                );
                                                            }}
                                                        />
                                                        <span>
                                                            <Button primary onClick={onOpenCollection}>Add
                                                                Collection</Button>
                                                        </span>
                                                    </Fragment>
                                                }
                                                {
                                                    x.type === "4" &&
                                                    <Grid>
                                                        <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 6, xl: 6}}>
                                                            <Select value={x.total_condition}
                                                                    onChange={(value) => onChange("total_condition", value, i)}
                                                                    options={[{label: "Greater than", value: "1"},
                                                                        {label: "Less than", value: "2"},
                                                                        {label: "Between", value: "3"},]}/>
                                                        </Grid.Cell>
                                                        <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 6, xl: 6}}>
                                                                <InlineStack gap={"200"} wrap={false} blockAlign={"center"}>
                                                                    <TextField type={"number"}
                                                                               prefix={currencySymbol[shopDetails.currency]}
                                                                               value={x.min}
                                                                               onChange={(value) => onChange("min", value, i)}/>
                                                                    {
                                                                        x.total_condition === "3" ?
                                                                            <Fragment>
                                                                                <Text>-</Text>
                                                                                <TextField type={"number"}
                                                                                           prefix={currencySymbol[shopDetails.currency]}
                                                                                           value={x.max}
                                                                                           onChange={(value) => onChange("max", value, i)}/>
                                                                            </Fragment> : ''
                                                                    }
                                                                </InlineStack>
                                                        </Grid.Cell>
                                                    </Grid>
                                                }
                                                {
                                                    x.type === "5" &&

                                                            <Combobox
                                                                allowMultiple
                                                                activator={
                                                                    <Combobox.TextField
                                                                        autoComplete="off"
                                                                        label="Customer tags"
                                                                        labelHidden
                                                                        value={selectedIndex === i ? value : ""}
                                                                        placeholder="Customer tags"
                                                                        verticalContent={verticalContentMarkup(x.customer_tags ? x.customer_tags : [],i,"customer_tags")}
                                                                        onChange={(e) => onChangeInput(e, i)}
                                                                    />
                                                                }
                                                            >
                                                                {
                                                                    selectedIndex === i && value ?    <Listbox
                                                                        autoSelection={AutoSelection.None}
                                                                        onSelect={(selected)=>updateSelection(selected,i,"customer_tags")}
                                                                        enableKeyboardControl={true}
                                                                    >
                                                                        <Listbox.Action value={value}>Add customer
                                                                            tag</Listbox.Action>
                                                                    </Listbox> : null
                                                                }
                                                            </Combobox>
                                                }
                                                {
                                                    x.type === "6" &&
                                                            <Combobox
                                                                allowMultiple
                                                                activator={
                                                                    <Combobox.TextField
                                                                        autoComplete="off"
                                                                        label="Product tags"
                                                                        labelHidden
                                                                        value={selectedIndex === i ? value : ""}
                                                                        placeholder="Product tags"
                                                                        verticalContent={verticalContentMarkup(x.product_tags,i,"product_tags")}
                                                                        onChange={(e) => onChangeInput(e, i)}
                                                                    />
                                                                }
                                                            >
                                                                {
                                                                    selectedIndex === i && value ?    <Listbox
                                                                        autoSelection={AutoSelection.None}
                                                                        onSelect={(selected)=>updateSelection(selected,i,"product_tags")}
                                                                        enableKeyboardControl={true}
                                                                    >

                                                                        <Listbox.Action value={value}>Add customer
                                                                            tag</Listbox.Action>
                                                                    </Listbox> : null
                                                                }

                                                            </Combobox>

                                                }
                                                {
                                                    (quoteCondition.length > 1 && quoteCondition.length - 1 !== i) ?
                                                            <span>AND</span>
                                                         : ''
                                                }

                                                        <Checkbox
                                                            label="Show Request a quote instead of Add to cart button"
                                                            checked={isAddToCartQuotes === 1}
                                                            onChange={(checked) => setIsAddToCartQuotes(checked ? 1 : 0)}/>

                                            </Fragment>
                                        )
                                    })
                                    }

                                 {
                                     quoteCondition.length === 5 ? "" :
                                         <span><Button primary onClick={onAddNewRule}>Add New Rule</Button></span>
                                 }
                            </BlockStack>
                        </Card>

                    </Layout.Section>
                    <Layout.Section variant="oneThird">
                        <Card>
                            <BlockStack gap={"400"}>
                                <Text id="storeDetails" variant="headingMd" as="h2">
                                    View all the requested form fields in the drafts
                                </Text>
                                <Text variant="bodyMd" color="subdued" as="p">
                                    Using this "Draft Order Tag" you can view all the requested form field details in your
                                    Shopify store's "Drafts" section under "Additional Details" mentioned on the right-hand
                                    side of the order.
                                </Text>
                            </BlockStack>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card>
                            <BlockStack gap={"400"}>
                                <Text variant="headingMd" as="h2">Draft order Tag</Text>
                                <Combobox
                                    allowMultiple
                                    activator={
                                        <Combobox.TextField
                                            autoComplete="off"
                                            label="Draft order Tag"
                                            labelHidden
                                            value={draftOrderValue}
                                            placeholder="Draft order Tag"
                                            verticalContent={verticalContentMarkupDraftOrder(draftOrderTags)}
                                            onChange={(e) => onChangeInputDraftOrder(e)}
                                        />
                                    }
                                >
                                    {
                                         draftOrderValue ?    <Listbox
                                            autoSelection={AutoSelection.None}
                                            onSelect={(selected)=>updateSelectionDraftOrder(selected)}
                                            enableKeyboardControl={true}
                                        >
                                            <Listbox.Action value={draftOrderValue}>Add Draft Order Tag </Listbox.Action>
                                        </Listbox> : null
                                    }
                                </Combobox>

                            </BlockStack>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </Fragment>
    );
};

export default ButtonCondition;