import React, {useEffect} from 'react';
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import '@shopify/polaris/build/esm/styles.css';
import DefaultLayout from './Component/DefaultLayout/DefaultLayout';
import { baseUrl } from './Utils/Constants';
import { routes } from './Utils/Routes';
import './App.css'

const App = () => {
  let urlParams = new URLSearchParams(window.location.search);
  return (
      <BrowserRouter>
        <Routes>
          <Route path={`${baseUrl}/`} element={<DefaultLayout />}>
            {
              routes.map((x, i) => {
                return (
                    <Route exact={true} key={i} path={x.path} element={x.component} />
                )
              })
            }
            <Route path={`${baseUrl}/`} element={<Navigate to={`${baseUrl}/dashboard?${urlParams}`} replace />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
};

export default App;