import { SET_SHOP_DETAILS, } from '../constent';

const initialState = {
    shop: "",
    domain: "",
    store_email: "",
    username: "",
    shop_plan: "",
    currency: "",
    plan_type: "",
    plan_interval: 0,
    trial_days: 0,
}

export default function shopDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SHOP_DETAILS: {
            return {
                ...state,
                ...action.payload,
            }
        }
        default: {
            return state
        }
    }
}
