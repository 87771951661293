import React, {useState, useEffect, useMemo, Suspense} from 'react';
import {AppProvider, Frame, Spinner,Link,FooterHelp,Text} from '@shopify/polaris';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Provider, RoutePropagator, NavigationMenu, ClientRouter} from "@shopify/app-bridge-react";
import esTranslations from '@shopify/polaris/locales/en.json';
import {apiService, appKey, baseUrl} from "../../Utils/Constants";
import {useDispatch, useSelector} from "react-redux";
import {shopDetailsAction} from "../../redux/action/shopDetailsAction";

const DefaultLayout = () => {
    const [isLoading, setIsLoading,] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(location.search);
    const host = urlParams.get("host");
    const shopDetails = useSelector((state) => state.shopDetails)

    useEffect(() => {
        const installApp = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const shop = urlParams.get("shop");
            const response = await apiService.installApp(shop);
            if (response.status === 200) {
                setIsLoading(false)
                dispatch(shopDetailsAction({...response.data, on_boardig:response.on_boardig}));
                if(response.data.plan_type == 0){
                    navigate(`${baseUrl}/pricing-plan`)
                }
            } else if (response.status === 401) {
                window.open(response.install_url, '_top')
            } else {
                setIsLoading(false)
            }
        };
        installApp();
    }, []);

    const config = {
        apiKey: appKey,
        host: host,
        forceRedirect: true
    };

    const history = useMemo(
        () => ({replace: (path) => navigate(path, {replace: true})}),
        [navigate],
    );


    return (
        <Suspense fallback={isLoading ? <div className="main_spinner">

                <Spinner accessibilityLabel="Spinner example" size="large" color="teal"/>

        </div> : ""}>
        <div>
            <AppProvider i18n={esTranslations}>
                {
                    isLoading ?
                        <div className="main_spinner">
                            <Spinner accessibilityLabel="Spinner example" size="large" color="teal"/>
                        </div> :
                        <Provider config={config}>
                            <ClientRouter history={history}/>
                            <NavigationMenu
                                navigationLinks={[
                                    {
                                        label: 'Dashboard',
                                        destination: shopDetails.plan_type == 0 ? `${baseUrl}/pricing-plan` : `${baseUrl}/dashboard`,
                                    },
                                    {
                                        label: 'Quotes Order(s)',
                                        destination:  `${baseUrl}/quote-order`,
                                    },
                                    {
                                        label: 'Settings',
                                        destination: `${baseUrl}/setting`,
                                    },
                                    {
                                        label: 'Pricing Plan',
                                        destination:  `${baseUrl}/pricing-plan`,
                                    },{
                                        label: 'Installation',
                                        destination: `${baseUrl}/installation`,
                                    },

                                ]}
                                matcher={(link, location) => link.destination === location.pathname}
                            />
                            <Frame>
                                        <RoutePropagator location={location}/>
                                        <Outlet/>
                                        {
                                            location.pathname === "/admin/support" ? "" :
                                                <FooterHelp>
                                                    <Text>
                                                        if you need any help, please{' '}
                                                        <Link onClick={() => navigate(`${baseUrl}/support`)}>
                                                            Contact us
                                                        </Link>
                                                    </Text>
                                                </FooterHelp>
                                        }
                            </Frame>
                        </Provider>
                }
            </AppProvider>
        </div>
        </Suspense>
    );
};

export default DefaultLayout;
