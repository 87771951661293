import React, { useState} from 'react';
import {Button, Card, Page, Text,BlockStack,Box,Divider,InlineStack,ActionList} from "@shopify/polaris";
import {useSelector} from "react-redux";
import {apiService} from "../../Utils/Constants";
import { CheckCircleIcon } from '@shopify/polaris-icons';

const PlanNew = () => {
    const shopDetails = useSelector((state) => state.shopDetails)
    const [isLoading, setIsLoading] = useState("")

    const onUpdatePlan = async(type) => {
        setIsLoading(type)
        const res = await apiService.upgradePlan({plan: type})
        if(res.status === 200 && res?.data?.confirmation_url){
            window.open(res.data.confirmation_url, '_top');
        } else {
            setIsLoading('')
        }
    }

    const planDetails = [
        "Unlimited Quotes",
        "Create Custom Form",
        "Add Quote Button in Product or Cart Page",
        "Button Customisation",
        "Button Conditions",
        "Advance Analytics"
        // "Add Quote Button on Cart Page",
        // "View Draft Orders",
        // "Manage Button Conditions",
        // "Customize Button Design",
        // "Track Total Views",
        // "Track Total Clicks",
        // "Track Total Orders",
        // "Works With All Themes",
    ];
    const planList = [
        // {
        //     title: 'Basic Plan',
        //     monthlyPrice: '9',
        //     planTypeMonthly: "1",
        //     planName: 'basic'
        // },
        // {
        //     title: 'Pro Plan',
        //     monthlyPrice: '19',
        //     planTypeMonthly: "2",
        //     planName: 'professional'
        // },
        {
            title: 'Awesome Plan',
            monthlyPrice: '29',
            planTypeMonthly: "3",
            planName: 'unlimited'
        },
        // {
        //     title: 'Plus Plan',
        //     monthlyPrice: '49',
        //     planTypeMonthly: "4",
        //     planName: 'shopify_plus'
        // },


    ];
    return (
        <Page title="Pricing Plan" narrowWidth >
            {/*{*/}
            {/*    shopDetails.shop_plan == "affiliate" || shopDetails.shop_plan == "partner_test" || shopDetails.shop_plan == "staff" || shopDetails.shop_plan == "plus_partner_sandbox" ?*/}
            {/*        <div className="mb-5"> <Banner*/}
            {/*            title="You are in development store"*/}
            {/*            status="info"*/}
            {/*        > <p>So you can use all our app features. Once you select your Shopify plan then you must have to upgrade our app plan.</p></Banner></div>: ""*/}
            {/*}*/}

                {
                    planList.map((x, i) =>{
                        return(
                                <Card padding={"0"}>
                                    <Box padding={"400"}>
                                        <BlockStack gap={"200"}>
                                            <Text variant="headingLg" as="h5">
                                                {x.title}
                                            </Text>
                                            <BlockStack gap={"100"}>
                                                 <InlineStack>
                                                     <Text tone={"success"} alignment={"end"} variant="heading2xl" as="h3">
                                                         ${x.monthlyPrice}
                                                     </Text>
                                                    <BlockStack align={"end"}>
                                                        <Text alignment={"end"}>/ month</Text>
                                                    </BlockStack>
                                                 </InlineStack>
                                                {
                                                    shopDetails.trial_days > 0 ? <Text as="p" tone="subdued">{shopDetails.trial_days} days trial</Text> : ''
                                                }
                                                <span>
                                                    <Button
                                                        loading={isLoading == x.planTypeMonthly}
                                                        onClick={() => onUpdatePlan(x.planTypeMonthly)}
                                                        disabled={shopDetails.plan_type == x.planTypeMonthly }
                                                        primary={shopDetails.plan_type == x.planTypeMonthly  ? false : true}>{shopDetails.plan_type == x.planTypeMonthly  ? "Activated" : "Activate"}</Button>
                                                </span>
                                            </BlockStack>
                                        </BlockStack>
                                    </Box>
                                    <Divider borderColor="border"/>
                                    <Box padding={""}>
                                        <ActionList
                                            actionRole="menuitem"
                                            sections={[
                                                {
                                                    items: [
                                                        {content: 'Unlimited Quotes', icon: CheckCircleIcon,},
                                                        {content: 'Create Custom Form', icon: CheckCircleIcon},
                                                        {content: 'Add Quote Button in Product or Cart Page', icon: CheckCircleIcon},
                                                        {content: 'Button Customisation', icon: CheckCircleIcon},
                                                        {content: 'Button Conditions', icon: CheckCircleIcon},
                                                        {content: 'Advance Analytics', icon: CheckCircleIcon},
                                                    ],
                                                },
                                            ]}
                                        />
                                    </Box>
                                </Card>
                        )
                    })
                }
        </Page>
    );
};

export default PlanNew;