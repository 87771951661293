import React, {useState, useEffect, Fragment, useCallback} from 'react';
import {apiService, baseUrl} from "../../Utils/Constants";
import {
    Page,
    Layout,
    Text,
    Card,
    TextField,
    Checkbox,
    Toast,
    Select,
    PageActions,
    Tabs,
    BlockStack,
    FormLayout,
    Divider,
    Box
} from "@shopify/polaris"
import Label from "../CommanComponents/Label";
import ColorInput from "../CommanComponents/ColorInput";
import {useNavigate} from "react-router-dom";
const initialState = {
    id: "",
    is_quote_enable: "",
    quote_popup_title: "",
    quote_popup_heading: "",
    quote_popup_title_align: "",
    quote_popup_heading_align: "",
    quote_popup_title_color: "",
    quote_popup_heading_color: "",
    quote_submit_button_text: "",
    quote_submit_button_color: "",
    quote_submit_button_background_color: "",
    quote_close_button_color: "",
    quote_cancel_button_color: "",
    quote_cancel_button_text: "",
    quote_button_text: "",
    quote_button_color: "",
    quote_button_background_color: "",
    quote_button_hover_background_color: "",
    quote_button_hover_color: "",
    quote_button_font_style: "",
    is_effect_quote_button: "",

    // is_add_to_cart_quotes: '',
    add_to_cart_btn_text: '',
    add_to_cart_btn_bg_color: '',
    add_to_cart_btn_text_color:'',
    add_to_cart_btn_border_color:'',
    add_to_cart_btn_bg_hover_color:'',
    add_to_cart_btn_text_hover_color: '',
    add_to_cart_btn_border_hover_color: '',
    add_to_cart_btn_font: '',
    is_redirect: '',
    redirect_page: '',
};

const ButtonDesign = () => {
    const [setting, setSetting] = useState(initialState);
    const [isSave, setIsSave] = useState(false);
    const [active, setActive] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [selected, setSelected] = useState(0);
    const [fontList, setFontList] = useState([]);

    let navigate = useNavigate()
    useEffect(() => {
        const getSetting = async () => {
            const response = await apiService.getSetting();
            if(response.status === 200){

                setSetting({...response.data,})
            } else {

            }
        };
        getSetting()
        getGoogleFont()
    },[]);

    const getGoogleFont = async () => {
        let fonts = [{label: 'Select Font', value: ''}];
        const res = await apiService.getGoogleFont()
        if (res?.items?.length > 0) {
            res.items.map((x) => {
                let obj = {
                    label: x.family,
                    value: x.family
                }
                fonts.push(obj)
            })
            setFontList(fonts)
        }
    }
    const onChange = (name, value) => {
        setSetting({...setting, [name]: value})
    };
    const onUpdateSetting = async () => {
        setIsSave(true);
        const payload = {
            ...setting,
        };
        const response = await apiService.updateSetting(payload);
        if (response.status === 200) {
            setIsSave(false);
            setActive(true);
            setMessage(response.message);
        } else {
            setIsSave(false);
            setActive(true);
            setMessage(response.message);
            setError(true)
        }
    }
    const toggleActive = () => {
        setActive(false);
        setError(false);
        setMessage("");
    };

    const toastMarkup = active ? (
        <Toast
            content={message}
            error={error}
            onDismiss={toggleActive}
            duration={2000}
        />
    ) : null;

    const tabs = [
        {
            id: 'cart-page',
            content: 'Cart Page',
            accessibilityLabel: 'Cart Page',
            panelID: 'cart-page',
        },
        {
            id: 'product-page',
            content: 'Product Page',
            panelID: 'product-page',
        },
    ]
    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );
    return (
        <Fragment>
            {toastMarkup}
            <Page title="Button Design" backAction={{content: 'Setting', onAction: () => navigate(`${baseUrl}/setting`)}}  primaryAction={{
                content: 'Save',
                onAction: onUpdateSetting,
                loading: isSave
            }}>
                <BlockStack gap={"400"}>
                    <Card padding={"0"}>
                        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
                    </Card>
                        {
                            selected === 0 &&   <Fragment>
                                <Layout>
                                    <Layout.Section variant={"oneThird"}>
                                        <Card>
                                            <BlockStack gap={"400"}>
                                                <Text id="storeDetails" variant="headingMd" as="h2">
                                                    Manage settings for the "Get Quote" button on the cart page
                                                </Text>
                                                <Text variant="bodyMd" color="subdued" as="p">
                                                    Here you can manage and customize Quote Button Settings, Header & Text Settings & Button Settings for the "Get Quote" button displayed on the cart page as per your theme look to make it more appealing.
                                                </Text>
                                            </BlockStack>
                                        </Card>
                                    </Layout.Section>
                                    <Layout.Section>
                                        <Card padding={"0"}>
                                            <Box padding={"400"}>
                                                <FormLayout>
                                                    <FormLayout.Group condensed>
                                                            <TextField label={"Button (Text)"} value={setting.quote_button_text} onChange={(value) => onChange("quote_button_text", value)}/>
                                                        <Box>
                                                            <Label name={"Button (Text Color)"}/>
                                                            <ColorInput name={"quote_button_color"} value={setting.quote_button_color} onChange={onChange}/>
                                                        </Box>
                                                        <Box>
                                                            <Label name={"Button (BG Color)"}/>
                                                            <ColorInput name={"quote_button_background_color"} value={setting.quote_button_background_color} onChange={onChange}/>
                                                        </Box>
                                                    </FormLayout.Group>
                                                    {/*</div>*/}
                                                    {/*<div className="row row-5">*/}
                                                    <FormLayout.Group condensed>
                                                        <Box>
                                                            <Label name={"Button Hover (Text Color)"}/>
                                                            <ColorInput name={"quote_button_hover_color"} value={setting.quote_button_hover_color} onChange={onChange}/>
                                                        </Box>
                                                        <Box>
                                                            <Label name={"Button Hover (BG Color)"}/>
                                                            <ColorInput name={"quote_button_hover_background_color"} value={setting.quote_button_hover_background_color} onChange={onChange}/>
                                                        </Box>
                                                        <div className="col col-md-4 col-12 ">
                                                            <Select label={"Font Family"} value={setting.quote_button_font_style } options={fontList} onChange={(value) => onChange("quote_button_font_style", value)}/>
                                                        </div>
                                                    </FormLayout.Group>

                                                            <Checkbox label="Glowing effect button" checked={setting.is_effect_quote_button === 1} onChange={(checked) => onChange("is_effect_quote_button", checked ? 1 : 0)}/>

                                                </FormLayout>
                                            </Box>
                                            <Divider/>
                                            <Box padding={"400"}>
                                                <BlockStack gap={"400"}>
                                                    <BlockStack gap={"200"}>
                                                        <Text as="h2" variant="headingSm">HEADER AND TEXT SETTINGS</Text>
                                                        <FormLayout.Group condensed>
                                                            <TextField label={"Title (Text)"} value={setting.quote_popup_title} onChange={(value) => onChange("quote_popup_title", value)}/>
                                                            <Select label="Title (Text align)" options={[{label: "Left", value: "1"}, {label: "Right", value: "2"}, {label: "Center", value: "3"}]}  onChange={(value) => onChange("quote_popup_title_align", value)} value={setting.quote_popup_title_align}/>
                                                            <Box>
                                                                <Label name={"Title (Color)"}/>
                                                                <ColorInput name={"quote_popup_title_color"} value={setting.quote_popup_title_color} onChange={onChange}/>
                                                            </Box>
                                                        </FormLayout.Group>
                                                    </BlockStack>
                                                    <FormLayout.Group condensed>

                                                        <TextField label={"Heading (Text)"} value={setting.quote_popup_heading} onChange={(value) => onChange("quote_popup_heading", value)}/>


                                                        <Select label="Heading (Text align)" options={[{label: "Left", value: "1"}, {label: "Right", value: "2"}, {label: "Center", value: "3"}]}  onChange={(value) => onChange("quote_popup_heading_align", value)} value={setting.quote_popup_heading_align}/>

                                                        <Box>
                                                            <Label name={"Heading (Color)"}/>
                                                            <ColorInput name={"quote_popup_heading_color"} value={setting.quote_popup_heading_color} onChange={onChange}/>
                                                        </Box>
                                                    </FormLayout.Group>
                                                </BlockStack>
                                            </Box>
                                            <Divider/>

                                            <Box padding={"400"}>
                                                <BlockStack gap={"400"}>
                                                        <BlockStack gap={"200"}>
                                                            <Text as="h2" variant="headingSm">BUTTON SETTINGS</Text>
                                                            <FormLayout.Group condensed>
                                                                        <TextField label={"Submit Button (Text)"} value={setting.quote_submit_button_text} onChange={(value) => onChange("quote_submit_button_text", value)}/>
                                                                <Box>
                                                                        <Label name={"Submit Button (Text Color)"}/>
                                                                        <ColorInput name={"quote_submit_button_color"} value={setting.quote_submit_button_color} onChange={onChange}/>
                                                                </Box>
                                                                <Box>
                                                                        <Label name={"Submit Button (BG Color)"}/>
                                                                        <ColorInput name={"quote_submit_button_background_color"} value={setting.quote_submit_button_background_color} onChange={onChange}/>
                                                                </Box>
                                                            </FormLayout.Group>
                                                        </BlockStack>
                                                       <FormLayout.Group condensed>
                                                               <TextField label={"Cancel Button (Text)"} value={setting.quote_cancel_button_text} onChange={(value) => onChange("quote_cancel_button_text", value)}/>
                                                           <Box>
                                                               <Label name={"Cancel Button (Color)"}/>
                                                               <ColorInput name={"quote_cancel_button_color"} value={setting.quote_cancel_button_color} onChange={onChange}/>
                                                           </Box>
                                                           <Box>
                                                               <Label name={"Close Button (Color)"}/>
                                                               <ColorInput name={"quote_close_button_color"} value={setting.quote_close_button_color} onChange={onChange}/>
                                                           </Box>
                                                       </FormLayout.Group>

                                                           <Checkbox label="Show Request Form Fields" checked={setting.is_quote_enable === 1} onChange={(checked) => onChange("is_quote_enable", checked ? 1 : 0)}/>
                                                </BlockStack>
                                            </Box>
                                            {/*</Card.Section>*/}
                                        {/*</FormLayout>*/}

                                        </Card>
                                    </Layout.Section>

                                    <Layout.Section variant="oneThird">
                                        <Card>
                                            <BlockStack gap={"400"}>
                                                <Text id="storeDetails" variant="headingMd" as="h2">
                                                    Quotes submitted after redirect settings
                                                </Text>
                                                <Text variant="bodyMd" color="subdued" as="p">
                                                    Here you can add a redirect page name where you want to submit all the quotes details. Doing this all the details will be redirected on the submitted page.
                                                </Text>
                                            </BlockStack>
                                        </Card>
                                    </Layout.Section>
                                    <Layout.Section>
                                        <Card title="Redirect page" sectioned>
                                            <div className="row row-5">
                                                <div className="col col-md-12 col-12 ">
                                                    <Checkbox label="Redirect page?" checked={setting.is_redirect === 1} onChange={(checked) => onChange("is_redirect", checked ? 1 : 0)}/>
                                                </div>
                                                {setting.is_redirect === 1 &&   <div className="col col-md-6 col-12 mt-2">
                                                    <TextField label={"Redirect page name"} value={setting.redirect_page} onChange={(value) => onChange("redirect_page", value)}/>
                                                </div>
                                                }
                                            </div>
                                        </Card>
                                    </Layout.Section>
                                </Layout>
                            </Fragment>
                        }
                        {
                            selected === 1 && <Layout>
                                <Layout.Section variant={"oneThird"}>
                                    <Card>
                                          <BlockStack gap={"400"}>
                                              <Text id="storeDetails" variant="headingMd" as="h2">
                                                  Manage settings for the "Request a quote" button on the product page
                                              </Text>
                                              <Text variant="bodyMd" color="subdued" as="p">
                                                  Here you can manage and customize button text and its text & background color. Set the button border color, hover text & background color for the product page and match it with your store's look to make it more attractive.
                                              </Text>
                                          </BlockStack>
                                    </Card>
                                </Layout.Section>
                                <Layout.Section variant={""}>
                                    <Card sectioned>
                                        <FormLayout>
                                            <FormLayout.Group condensed>
                                                  <div className="col col-md-4 col-12 ">
                                                      <TextField label={"Button (Text)"} value={setting.add_to_cart_btn_text} onChange={(value) => onChange("add_to_cart_btn_text", value)}/>
                                                  </div>
                                                  <div className="col col-md-4 col-12 ">
                                                      <Label name={"Button (Text Color)"}/>
                                                      <ColorInput name={"add_to_cart_btn_text_color"} value={setting.add_to_cart_btn_text_color} onChange={onChange}/>
                                                  </div>
                                                  <div className="col col-md-4 col-12 ">
                                                      <Label name={"Button (BG Color)"}/>
                                                      <ColorInput name={"add_to_cart_btn_bg_color"} value={setting.add_to_cart_btn_bg_color} onChange={onChange}/>
                                                  </div>
                                                  </FormLayout.Group>

                                            <FormLayout.Group condensed>
                                               <div className="col col-md-4 col-12 ">
                                                   <Label name={"Button (Border Color)"}/>
                                                   <ColorInput name={"add_to_cart_btn_border_color"} value={setting.add_to_cart_btn_border_color} onChange={onChange}/>
                                               </div>
                                               <div className="col col-md-4 col-12 ">
                                                   <Label name={"Button Hover (Text Color)"}/>
                                                   <ColorInput name={"add_to_cart_btn_text_hover_color"} value={setting.add_to_cart_btn_text_hover_color} onChange={onChange}/>
                                               </div>

                                            </FormLayout.Group>

                                              <FormLayout.Group condensed>
                                                  <div className="col col-md-4 col-12 ">
                                                      <Label name={"Button Hover (BG Color)"}/>
                                                      <ColorInput name={"add_to_cart_btn_bg_hover_color"} value={setting.add_to_cart_btn_bg_hover_color} onChange={onChange}/>
                                                  </div>
                                                  <div className="col col-md-4 col-12 ">
                                                      <Label name={"Button Hover (border Color)"}/>
                                                      <ColorInput name={"add_to_cart_btn_border_hover_color"} value={setting.add_to_cart_btn_border_hover_color} onChange={onChange}/>
                                                  </div>

                                              </FormLayout.Group>

                                            <FormLayout.Group condensed>
                                                <div className="col col-md-4 col-12 ">
                                                    <Select label={"Font Family"} value={setting.add_to_cart_btn_font } options={fontList} onChange={(value) => onChange("add_to_cart_btn_font", value)}/>
                                                </div>
                                            </FormLayout.Group>
                                        </FormLayout>
                                    </Card>
                                </Layout.Section>
                            </Layout>
                        }
                </BlockStack>
                <div className="mt-3">
                    <PageActions primaryAction={{ content: 'Save',
                        onAction: onUpdateSetting,
                        loading: isSave}}/>
                </div>
            </Page>

        </Fragment>
    );
};

export default ButtonDesign;