import React, { useCallback, useEffect, useState} from 'react';
import {
    Button,
    Card,
    DataTable,
    EmptyState,
    ExceptionList,
    BlockStack,
    Layout,
    Page,
    SkeletonBodyText,
    Tag,
    Text,
    Divider,
    Box
} from "@shopify/polaris";
import {apiService, baseUrl, currencySymbol} from "../../Utils/Constants";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {EmailIcon, PhoneIcon} from "@shopify/polaris-icons";


const QuoteOrderDetails = () => {
    let  {id} = useParams();
    let navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const shopDetails = useSelector((state) => state.shopDetails)
    useEffect(()=> {
        const getQuoteDetails = async () => {
            setIsLoading(true)
            const response = await apiService.getQuoteDetails(id)
            if(response.status === 200){
                setOrderDetails(response.data)
                setIsLoading(false)
            }else {
                setIsLoading(false)
            }
        };
        getQuoteDetails();

    },[])
    console.log(orderDetails)

    const renderData = useCallback(() => {
        let quoteListData = [];
        if(isLoading){
            Array.from(Array(10)).map((_, i) => {
                let obj = [
                    <SkeletonBodyText lines={1}/>,
                    <SkeletonBodyText lines={1}/>,
                    <SkeletonBodyText lines={1}/>,
                    <SkeletonBodyText lines={1}/>,
                ];
                quoteListData.push(obj)
            })
        } else if (orderDetails && orderDetails?.line_items?.length) {
            (orderDetails.line_items || []).map((x, i) => {
                let obj = [
                    <Button textAlign="left" variant={'plain'} onClick={() => window.open(`https://${shopDetails.shop}/admin/products/${x.product_id}`, "_blank")}>{x.title}</Button>,
                    <span>{currencySymbol[shopDetails.currency]}{x.price}</span>,
                    <span>{x.quantity}</span>,
                    <span>{currencySymbol[shopDetails.currency]}{(parseFloat(x.price) * x.quantity).toFixed(2)}</span>,
                ];
                quoteListData.push(obj)
            })
        } else {
            let obj = [<EmptyState
                heading="No line items"
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                fullWidth
            >
            </EmptyState>];
            quoteListData.push(obj)
        }
        return quoteListData
    }, [isLoading]);



    return (
        <Page
            title={`Draft Order ${orderDetails?.name}`} backAction={{content: 'Products', onAction: () => navigate(`${baseUrl}/quote-order`)}}
            secondaryActions={
                <Button onClick={() => window.open(`https://${shopDetails.shop}/admin/draft_orders/${id}`, "_blank")}>
                    View
                </Button>
            }
        >
            <Layout>
                <Layout.Section>
                    <Card padding={"0"}>
                        <BlockStack gap={'0'}>
                            <Box padding={"200"} paddingInlineStart={"400"}>
                                <Text as="h2" variant="headingSm">Line Items</Text>
                            </Box>

                            <DataTable
                                columnContentTypes={[
                                'text',
                                'numeric',
                                'numeric',
                                'numeric',
                            ]}
                                headings={[
                                'Title',
                                'Price',
                                'QTY',
                                'Total',
                            ]}
                                verticalAlign={"middle"}
                                rows={renderData()}
                                showTotalsInFooter
                                totals={['', '', '', <BlockStack gap={'200'}>
                                <Text alignment={'end'} as="h2" variant="headingSm">{currencySymbol[shopDetails.currency]}{orderDetails?.subtotal_price}</Text>
                                <Text alignment={'end'} as="h2" variant="headingSm">{currencySymbol[shopDetails.currency]}{orderDetails?.total_tax}</Text>
                                <Text alignment={'end'} as="h2" variant="headingSm">{currencySymbol[shopDetails.currency]}{orderDetails?.total_price}</Text>
                            </BlockStack>
                                ]}
                                totalsName={{
                                    singular: <BlockStack gap={'200'}>
                                        <Text alignment={'end'} as="h2" variant="headingSm">Subtotal</Text>
                                        <Text alignment={'end'} as="h2" variant="headingSm">Total Tax</Text>
                                        <Text alignment={'end'} as="h2" variant="headingSm">Total</Text>
                                    </BlockStack>,
                                }}
                            />
                        </BlockStack>
                    </Card>
                </Layout.Section>
                <Layout.Section variant="oneThird">
                    <BlockStack gap={"400"}>
                        <Card padding={"0"}>
                            <BlockStack gap={'0'}>
                                <Box padding={"400"}>
                                    <BlockStack gap={'400'}>
                                        <Text as="h2" variant="headingSm">Customer</Text>
                                        <BlockStack gap={"100"}>
                                            {
                                                orderDetails?.customer?.id ?<span><Button variant={'plain'} onClick={orderDetails?.customer?.id ? () => window.open(`https://${shopDetails.shop}/admin/customers/${orderDetails?.customer?.id}`, "_blank") : null}>{orderDetails?.customer?.first_name} {orderDetails?.customer?.last_name}</Button></span>  : ''
                                            }
                                            <Text>{orderDetails?.customer?.orders_count} Order</Text>
                                        </BlockStack>
                                    </BlockStack>
                                </Box>
                                <Divider />
                                <Box padding={"400"}>
                                    <BlockStack gap={'400'}>
                                        <Text variant="headingMd" as="h6">Contact information</Text>
                                        <ExceptionList
                                            items={[
                                                {
                                                icon: EmailIcon,
                                                description: orderDetails?.customer?.email ? orderDetails?.customer.email : "No email"
                                            },
                                            {
                                                icon: PhoneIcon,
                                                description: orderDetails?.customer?.phone ? orderDetails?.customer?.phone: "No phone number"
                                            },]}
                                        />
                                    </BlockStack>
                                </Box>
                                <Divider />

                            <Box padding={"400"}>
                                <BlockStack gap={"400"}>
                                    <Text variant="headingMd" as="h6">Shipping address</Text>
                                    <BlockStack>
                                        <Text>{orderDetails?.customer?.default_address.name}</Text>
                                        <Text>{orderDetails?.customer?.default_address.address1}</Text>
                                        <Text>{orderDetails?.customer?.default_address.address2}</Text>
                                        <Text>{orderDetails?.customer?.default_address.zip} {orderDetails?.customer?.default_address.province} {orderDetails?.customer?.default_address.province_code}</Text>
                                        <Text>{orderDetails?.customer?.default_address.country}</Text>
                                        <Text>{orderDetails?.customer?.default_address.phone}</Text>
                                    </BlockStack>
                                </BlockStack>
                            </Box>
                            <Divider/>
                            <Box padding={'400'}>
                                <BlockStack gap={"400"}>
                                    <Text variant="headingMd" as="h6">Billing address</Text>
                                    <Text as={"p"}>Same as shipping address</Text>
                                </BlockStack>
                            </Box>
                            </BlockStack>
                    </Card>
                        <Card>
                            <BlockStack gap={'400'}>
                                <Text  as="h2" variant="headingSm">Tags</Text>
                                {orderDetails?.customer?.tags ? <BlockStack spacing="extraTight"> {orderDetails?.customer?.tags.split(",").map((x, i) => {
                                    return <Tag key={i}>{x}</Tag>
                                })} </BlockStack>: "No Tag Found"}
                            </BlockStack>
                    </Card>
                    </BlockStack>
                </Layout.Section>
            </Layout>
        </Page>
    );
};

export default QuoteOrderDetails;