import React, {useState, useEffect} from 'react';
import {
    Layout,
    Page,
    Text,
    SkeletonBodyText,
    Button,
    Card,
    BlockStack,
    IndexTable,
    EmptySearchResult,
    Box
} from "@shopify/polaris";
import {apiService, baseUrl, currencySymbol} from "../../Utils/Constants";
import moment from "moment";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const QuoteOrder = () => {
    const navigate = useNavigate()
    const [quoteList, setQuoteList] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [next, setNext] = useState("");
    const [previous, setPrevious] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const shopDetails = useSelector((state) => state.shopDetails)
    useEffect(() => {
        const payload = {
            "cursor" : "",
            is_previous:0
        }
        getQuotes(payload)
    },[])

    const getQuotes = async (payload) => {
        setIsLoading(true);

        const response = await apiService.getQuotes(payload)
        if(response.status === 200){
            setQuoteList(response.data.orders);
            setHasNextPage(response.data.pages.hasNextPage);
            setHasPreviousPage(response.data.pages.hasPreviousPage);
            setNext(response.data.next);
            setPrevious(response.data.previous);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    const onNext = () => {
        const payload = {
            "cursor" : next,
            is_previous:0
        }
        getQuotes(payload)
    }
    const onPrevious = () => {
        const payload = {
            "cursor" : previous,
            is_previous:1
        }
        getQuotes(payload)
    }

    const resourceName = {
        singular: 'customer',
        plural: 'customers',
    };

    const rowMarkup = (quoteList || []).map((x, index,) => (
            <IndexTable.Row
                id={x.id}
                key={x.id}
                position={index}
            >
                <IndexTable.Cell>
                    <Button variant={"plain"} onClick={() => navigate(`${baseUrl}/quote-order/${x.id.replace("gid://shopify/DraftOrder/", "")}`)}>{x.name}</Button>,
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {moment(x.createdAt).format('lll')}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {x.customer?.displayName}
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {currencySymbol[shopDetails.currency]}{x.totalPrice}
                </IndexTable.Cell>
            </IndexTable.Row>
        ),
    );
    return (
        <Page title="Quotes Order(s)">
            <Layout>
                <Layout.Section variant="oneThird">
                    <Card>
                        <BlockStack gap={'400'}>
                            <Text  variant="headingMd" as="h2">
                            View all your quote orders in one place
                            </Text>
                            <Text variant="bodyMd" tone="subdued" as="p">
                            Here all the quote orders generated by clicking on the “Get Quotes” button will be visible. You can check the quote order details such as products, price, quantity & total by clicking on the draft order number.
                            </Text>
                        </BlockStack>
                    </Card>
                </Layout.Section>
                <Layout.Section >
                    <Card padding={"0"}>
                          <IndexTable
                              emptyState={<EmptySearchResult title={'No Quotes Order(s) found'} withIllustration={!isLoading}/>}
                              resourceName={resourceName}
                              itemCount={isLoading ? 1 : quoteList.length}
                              headings={[
                                  {title:"Draft"},
                                  {title:"Date"},
                                  {title:"Customer"},
                                  {title:"Total"}
                              ]}
                              selectable={false}
                              pagination={{
                                  hasNext: hasNextPage,
                                  onNext: onNext,
                                  hasPrevious:hasPreviousPage,
                                  onPrevious:onPrevious
                              }}
                          >
                              {
                                  isLoading ?
                                      Array.from(Array(5)).map((_, i) => (
                                          <IndexTable.Row key={i}>
                                              <IndexTable.Cell><Box padding={"200"}><SkeletonBodyText lines={1}/></Box></IndexTable.Cell>
                                              <IndexTable.Cell><Box padding={"200"}><SkeletonBodyText lines={1}/></Box></IndexTable.Cell>
                                              <IndexTable.Cell><Box padding={"200"}><SkeletonBodyText lines={1}/></Box></IndexTable.Cell>
                                              <IndexTable.Cell><Box padding={"200"}><SkeletonBodyText lines={1}/></Box></IndexTable.Cell>
                                          </IndexTable.Row>
                                      )) :
                                      rowMarkup}
                          </IndexTable>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    );
};

export default QuoteOrder;