import axios from "axios";
import {getSessionToken} from "@shopify/app-bridge-utils";
import createApp from "@shopify/app-bridge";
//test
//const baseUrl = 'https://quotes.uncap.com/quotes-test';
//Live
const baseUrl = 'https://quotes.uncap.com/quotes';
const instance = axios.create();
const urlParams = new URLSearchParams(window.location.search);
const host = urlParams.get('host');
//test
//const appKey = 'e66b16d2678bcea8d41a6e69b3c4c8b3';

//Live
const appKey = '72017730e68a13935dd1cf6596a33e68';

if (host && window.self !== window.top) {
    const app = createApp({
        apiKey: appKey,
        host
    });
    instance.interceptors.request.use(function (config) {
        return getSessionToken(app) // requires a Shopify App Bridge instance
            .then((token) => {
                // Append your request headers with an authenticated token
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
            });
    });
} else {
    instance.interceptors.request.use(function (config) {
        let urlParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlParams)
        config.headers["Authorization"] = JSON.stringify(params);
        return config;
    })
}


export class ApiService {
    async getData(url, header) {
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                ...header || {}
            }
        };
        let resData = '';
        let response = '';
        await instance.get(url, config).then((res) => {
            if (res.data.status === 200) {
                response = res.data;
            } else {
                response = res.data
            }
        }).catch((e) => {
            resData = e && e.response && e.response.data;
        })
        return resData || response
    }

    async postData(url, data, header) {
        const config = {
            headers: {
                'content-type': 'application/json',
                ...header || {}
            }
        }
        let resData = '';
        let response = '';
        await instance.post(url, data, config).then((res) => {
            if (res.data.status === 200) {
                response = res.data;
            } else {
                response = res.data
            }
        }).catch((e) => {
            resData = e.response.data;
        })
        return resData || response
    }

    async deleteData(url, header) {
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                ...header || {}
            }
        }
        let resData = '';
        let response = '';
        await instance.delete(url, config).then((res) => {
            if (res.data.status === 200) {
                response = res.data;
            } else {
                response = res.data
            }
        }).catch((e) => {
            resData = e.response.data;
        })
        return resData || response
    }

    async installApp(payload) {
        return await this.postData(`${baseUrl}/api/shop`, {shop: payload})
    }
    async getFields() {
        return await this.getData(`${baseUrl}/api/fields`)
    }
    async createField(payload) {
        return await this.postData(`${baseUrl}/api/field`,payload)
    }
    async deleteField(id) {
        return await this.deleteData(`${baseUrl}/api/field/${id}`)
    }
    async getSetting() {
        return await this.getData(`${baseUrl}/api/setting`)
    }
    async updateSetting(payload) {
        return await this.postData(`${baseUrl}/api/setting`, payload)
    }
    async getQuoteRule() {
        return await this.getData(`${baseUrl}/api/quote-rule`)
    }
    async updateQuoteRule(payload) {
        return await this.postData(`${baseUrl}/api/quote-rule`, payload)
    }
    async getQuotes(payload) {
        return await this.postData(`${baseUrl}/api/quotes`, payload)
    }
    async getQuoteDetails(id) {
        return await this.getData(`${baseUrl}/api/draft/${id}`)
    }
    async getAnalytics(payload) {
        return await this.postData(`${baseUrl}/api/analytics`, payload)
    }
    async getThemes() {
        return await this.getData(`${baseUrl}/api/themes`)
    }
    async autoInstall(payload) {
        return await this.postData(`${baseUrl}/api/change-theme`, payload)
    }
    async upgradePlan(payload) {
        return await this.postData(`${baseUrl}/api/upgrade`, payload)
    }

    async getGoogleFont() {
        return await this.getData(`https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBX73D-SYoUl7-gdw7ZqKyLEnISwALBxZ8`)
    }
}

